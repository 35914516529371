import { Link } from 'react-router-dom';
import Img_phone from '../../../assets/img/Links/phone.png';
import './LinkPhone.css';
import FormaZayavka from './Forma_zayavka';

function Phone() {
  return (
    <div className="header__phone">
      <Link to={'tel:+48506778684'}>
        <img
          title="phone"
          src={Img_phone}
          alt="phone"
          className="header__phone-img"
        />
      </Link>
      <div className="header__phone-schedule">
        <span className="header__schedule">6:00-22:00/7</span>
        <Link to={'tel:+48506778684'}>
          <h3 className="header-phone-item">+48 506 778 684</h3>
        </Link>
      </div>
    </div>
  );
}

function LinkPhone() {
  return (
    <>
      <div className="header__info">
        <Phone />
        <FormaZayavka />
      </div>
    </>
  );
}
export default LinkPhone;
export { Phone };
