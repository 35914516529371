import { NavLink } from 'react-router-dom';
import { useProject } from '../../../ProjectProvider';
import './Nav.css';
import '../../../media.css';
function Nav() {
  const { closeBurgerMenu } = useProject();
  return (
    <>
      <nav className="menu">
        <ul className="menu__list">
          <li className="menu__item">
            <NavLink to="/" className="menu__link" onClick={closeBurgerMenu}>
              Główny
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink
              to="/about"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              O nas
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink
              to="/price"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Cennik
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink
              to="/gallery"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Galeria
            </NavLink>
          </li>
          <li className="menu__item">
            <NavLink
              to="/contakt"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Nav;
