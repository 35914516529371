import Header from '../../Header/Container/Header';
import Footer from '../../Footer/Footer';
import './Price.css';
import WhatsappLink from '../../WhatsappLink/WhatsappLink';
import camera from '../../../assets/img/price-camera.png';

function Price() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="price">
          <div className="price-title">
            <h1>Cennik</h1>
          </div>
          <div className="container price-container">
            <div className="price__container">
              <div className="price__item">
                <div className="price__item-text">
                  <div className="price__item-text-h2h3">
                    <h2>FILMOWANIE DRONEM</h2>
                    <h3>od: 200 PLN</h3>
                  </div>
                  <ul>
                    <li>
                      <span className="price__item-text-icon"></span>
                      <p>filmy promocyjne dla miast</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon"></span>
                      <p>prezentacje nieruchomości</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon"></span>
                      <p>dzienniki budowy</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon"></span>
                      <p>filmowanie działek + mapki</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon"></span>
                      <p>filmy reklamowe na social media</p>
                    </li>
                  </ul>
                </div>

                <div className="price__item-text">
                  <div className="price__item-text-h2h3">
                    <h2>PANORAMY SFERYCZNE 360°</h2>
                    <h3>od: 200 PLN</h3>
                  </div>

                  <ul>
                    <li>
                      <span className="price__item-text-icon3"></span>
                      <p> planowanie przestrzeni </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon3"></span>
                      <p> odkrywanie detali okolicy </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon3"></span>
                      <p>podkreślenie atutów lokalizacji </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon3"></span>
                      <p> efekt WOW! na prezentacji </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon3"></span>
                      <p>wirtualny spacer po niebie </p>
                    </li>
                  </ul>
                </div>
                <div className="price__item-text">
                  <div className="price__item-text-h2h3">
                    <h2>FILMY REKLAMOWE </h2>
                    <h3>od: 1000 PLN</h3>
                  </div>

                  <ul>
                    <li>
                      <span className="price__item-text-icon4"></span>
                      <p> krótkie filmy na Facebooka </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon4"></span>
                      <p> video pionowe na Instagram </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon4"></span>
                      <p> filmy reklamowe </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon4"></span>
                      <p> filmy na stronę www/Youtube</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon4"></span>
                      <p>spoty reklamowe do TV </p>
                    </li>
                  </ul>
                </div>

                <div className="price__item-text">
                  <div className="price__item-text-h2h3">
                    <h2>MONTAŻ / POSTPRODUKCJA</h2>
                    <h3>od: 200 PLN</h3>
                  </div>
                  <ul>
                    <li>
                      <span className="price__item-text-icon5"></span>
                      <p>montaż </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon5"></span>
                      <p>projekt grafiki </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon5"></span>
                      <p>animacje 2D/3D </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon5"></span>
                      <p>animowane napisy </p>
                    </li>
                    <li>
                      <span className="price__item-text-icon5"></span>
                      <p> color grading</p>
                    </li>
                  </ul>
                </div>
                <div className="price__item-text">
                  <div className="price__item-text-h2h3">
                    <h2>Profesjonalna fotografia</h2>
                    <h3>od: 200 PLN</h3>
                  </div>

                  <ul>
                    <li>
                      <span className="price__item-text-icon2"></span>
                      <p>na portale ogłoszeniowe</p>
                    </li>
                    <li>
                      {/* <span className="price__item-text-icon2"></span> */}
                      <img className="price__item-camera" src={camera} alt="" />
                      <p>do druku - bannery, ulotki, kalendarze, foto-obrazy</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon2"></span>
                      <p>reklama w Internecie</p>
                    </li>
                    <li>
                      <span className="price__item-text-icon2"></span>
                      <p>materiały prasowe </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <WhatsappLink />
      </div>
    </>
  );
}
export default Price;
