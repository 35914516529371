import VideoPlayer from 'react-player';

import urody from '../../assets/video/urody.mp4';
import video2 from '../../assets/video/video2.mp4';
import video3 from '../../assets/video/video3.mp4';
import video4 from '../../assets/video/video4.mp4';
import video5 from '../../assets/video/video5.mp4';
import video6 from '../../assets/video/video6.mp4';

function ReactVideoPlayer() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={true}
      controls={true}
      url={urody}
    />
  );
}
function ReactVideoPlayer2() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      loop={true}
      controls={true}
      url={video2}
    />
  );
}
function ReactVideoPlayer3() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      loop={true}
      controls={true}
      url={video3}
    />
  );
}
function ReactVideoPlayer4() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      loop={true}
      controls={true}
      url={video4}
    />
  );
}
function ReactVideoPlayer5() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      loop={true}
      controls={true}
      url={video5}
    />
  );
}
function ReactVideoPlayer6() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      loop={true}
      controls={true}
      url={video6}
    />
  );
}

export {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  ReactVideoPlayer4,
  ReactVideoPlayer5,
  ReactVideoPlayer6,
};
