import './Gallery.css';

import youtube from '../../../assets/img/praca-youtube.png';
import { Link } from 'react-router-dom';
import {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  ReactVideoPlayer4,
  ReactVideoPlayer5,
  ReactVideoPlayer6,
} from '../../ReactVideoPlayer2/ReactVideoPlayer2';

function Gallery() {
  return (
    <>
      <div className="main__second-services">
        <h1 className="main-title-gallery">Nasze portfolio</h1>
        <div className="main__gallery">
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer />
            </div>
            <h2>Film reklamowy dla salon urody</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/F97t7bAbFNg?feature=shared"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer2 />
            </div>
            <h2>Nagranie budowy z drona</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/9Z0FBTN2We0?feature=shared"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer3 />
            </div>
            <h2>Film reklamowy dla policjantów</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/Iy4dQdCXSf4?feature=share"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer4 />
            </div>
            <h2>Urodziny Harley Davidson</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/2GTieNfrxeM?feature=shared"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer5 />
            </div>
            <h2>Film reklamowy dla służby pogotowie ratunkowe</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/AkZ7_ekc1eA?feature=shared"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer6 />
            </div>
            <h2>Film reklamowy dla dewelopera</h2>
            <Link
              className="main__gallery-youtube-img"
              to="https://youtu.be/KDKllQB9IIE?feature=shared"
              alt="photo1"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </Link>
          </div>
        </div>

        <div className="main__link-details">
          <a href="/gallery">Detale...</a>
        </div>
      </div>
    </>
  );
}
export default Gallery;
