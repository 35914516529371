import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Phone } from '../Header/LinkPhoneSocial/LinkPhone';
import LinkSocial from '../Header/LinkPhoneSocial/LinkSocial';

import './Footer.css';
import '../../media.css';

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <nav className="footer-menu">
      <ul className="footer__menu__list">
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/"
            className="footer__menu__link"
          >
            Główny
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/about"
            className="footer__menu__link"
          >
            O nas
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/price"
            className="footer__menu__link"
          >
            Cennik
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/gallery"
            className="footer__menu__link"
          >
            Galeria
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/contakt"
            className="footer__menu__link"
          >
            Kontakt
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <FooterMenu />
            </div>
            <div className="footer__title_phone">
              <div className="footer-phone header__info">
                <Phone />
              </div>
            </div>
            <div className="footer__social-links">
              <LinkSocial />
            </div>
          </div>
        </div>
        <div className="footer__down">
          <h3>Usługi Dronem | Vital Fly | 2023 </h3>
        </div>
      </div>
    </>
  );
}
export default Footer;
