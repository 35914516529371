import './Services.css';
import main1 from '../../../assets/img/photo2.jpg';
import main2 from '../../../assets/img/main2.jpg';
import main3 from '../../../assets/img/main3.jpg';
import main5 from '../../../assets/img/main5.jpg';
import camera from '../../../assets/img/camera.png';
// import burning from '../../../assets/img/burning.png';
import photograph from '../../../assets/img/photographer.png';
import drone from '../../../assets/img/drone.png';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title">
          <h1>
            <img className="main-drone-img" src={drone} alt="drone"></img>
            Usługi Dronem
          </h1>
          <h2>
            <img className="main-camera-img" src={camera} alt="camera"></img>
            Produkcja filmów
            {/* <img
              className="main-camera-img-rigth camera-closed"
              src={camera}
              alt="camera"
            ></img> */}
          </h2>
          <h2>
            <img className="main-camera-img" src={photograph} alt="" />
            Usługi fotograficzne
          </h2>
          {/* <h3>
            <img className="main-burning-img" src={burning} alt="camera"></img>
            Szybki czas realizacji
            <img className="main-burning-img" src={burning} alt="camera"></img>
          </h3> */}
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>ZDJĘCIA Z DRONA</h2>
            <p className="main-services-price">od 200 pln</p>
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main5} alt="photo1" />
            </div>
            <h2>FILMOWANIE DRONEM</h2>
            <p className="main-services-price">od 200 pln</p>
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>PANORAMY SFERYCZNE 360°</h2>
            <p className="main-services-price">od 200 pln</p>
          </div>
          <div className="main__services-item">
            <div className="nain__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>FILMY REKLAMOWE</h2>
            <p className="main-services-price">od 200 pln</p>
          </div>
        </div>
        <div className="main__link-details">
          <a href="/price">Detale...</a>
        </div>
      </div>
    </>
  );
}
export default Services;
