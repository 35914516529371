import './WhatsappLink.css';
import { Link } from 'react-router-dom';
import whatsapp from '../../assets/img/link-whatsapp2.png';

function WhatsappLink() {
  return (
    <>
      <div className="whatsappLink">
        <Link to="https://wa.me/48506778684">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
        </Link>
      </div>
    </>
  );
}
export default WhatsappLink;
