import { Link } from 'react-router-dom';
import logo from './../../../assets/img/logo_dron.jpg';
import './Logo.css';
function Logo() {
    return (
        <>
            <Link to="/" className="logo">
                <img className="logo__img" src={logo} alt="logo-dron-flying" />
            </Link>
        </>
    );
}
export default Logo;
