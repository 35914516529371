import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Container/Header';
import whatsapp from '../../../assets/img/contats-whatsapp.png';

import './Contacts.css';
import LinkSocial from '../../Header/LinkPhoneSocial/LinkSocial';
import WhatsappLink from '../../WhatsappLink/WhatsappLink';

function Contakt() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="contacts">
          <div className="contacts-title">
            <h1>Kontakt</h1>
          </div>
          <div className="container contacts__container">
            <div className="contacts__item">
              <div className="contacts__item-phone">
                <p>Numer telefonu:</p>
                <Link to={'tel:+48506778684'}>
                  <h2 className="header-phone-item">+48 506 778 684</h2>
                </Link>
              </div>
              <div className="contacts__item-whatsapp">
                <p className="contacts__item-whatsapp-text">WhatsApp:</p>
                <Link to="https://wa.me/48506778684">
                  <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
                </Link>
              </div>

              <div className="contacts__item-social">
                <p>Jesteśmy w sieciach społecznościowych:</p>
                <div className="contacts__item-social-links">
                  <LinkSocial />
                </div>
              </div>
              <div className="contacts__item-praca">
                <h2>Plan pracy</h2>
                <p>Pracujemy na terenie Krakowa i okolic </p>
                <p>Pracujemy 7 dni w tygodniu, 6:00-22:00</p>
                <p>Telefon odbieramy od 6:00 do 22:00 - 7 dni w tygodniu</p>
                <p>Wystawiam fakturę - VAT</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts__footer">
          <Footer />
        </div>
        <WhatsappLink />
      </div>
    </>
  );
}
export default Contakt;
