import { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();
const useProject = () => useContext(ProjectContext);

function ProjectProvider({ children }) {
  const [isMenu, setIsMenu] = useState(false);
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const openMenu = () => {
    setIsMenu(true);
  };
  const closeMenu = () => {
    setIsMenu(false);
  };

  const open = () => {
    setIsBurgerMenu((isBurgerMenu) => !isBurgerMenu);
  };
  const closeBurgerMenu = () => {
    setIsBurgerMenu(false);
  };

  return (
    <ProjectContext.Provider
      value={{
        isBurgerMenu,
        open,
        isScroll,
        setIsScroll,
        isMenu,
        setIsMenu,
        openMenu,
        closeMenu,
        closeBurgerMenu,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export { useProject, ProjectProvider };
