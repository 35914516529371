import { v4 } from 'uuid';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cube';

import Header from '../../Header/Container/Header';
import './Gallery.css';

import main1 from './img-gallery/main.jpg';
import main2 from './img-gallery/main2.jpg';
import main3 from './img-gallery/main3.jpg';
import main4 from './img-gallery/main4.jpg';
import main5 from './img-gallery/main5.jpg';
import main6 from './img-gallery/main6.jpg';
import main7 from './img-gallery/main7.jpg';
import main8 from './img-gallery/main8.jpg';
import main9 from './img-gallery/main9.jpg';
import main10 from './img-gallery/photo2.jpg';
import main11 from './img-gallery/main16.jpg';
import main12 from './img-gallery/main17.jpg';
import main13 from './img-gallery/main18.jpg';
import main14 from './img-gallery/main19.jpg';
import main15 from './img-gallery/main20.jpg';
import Footer from '../../Footer/Footer';
import WhatsappLink from '../../WhatsappLink/WhatsappLink';

const uuidv4 = v4;

function Gallery() {
  return (
    <>
      <div className="body_wrapper">
        <Header />

        <div className="gallery">
          <div className="gallery-title">
            <h1>Galeria</h1>
          </div>
          <div className="container container__gallery">
            <Swiper
              modules={[Pagination, Navigation, Scrollbar, A11y, EffectCube]}
              effect={'cube'}
              grabCursor={true}
              cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              pagination={{ clickable: true }}
              className="mySwiper"
              navigation
              scrollbar={{ draggable: true }}
            >
              {[1].map((i, el) => {
                return (
                  <div key={uuidv4()}>
                    <SwiperSlide>{<img src={main2} alt="main1" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main1} alt="main2" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main3} alt="main3" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main4} alt="main1" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main5} alt="main2" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main6} alt="main3" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main7} alt="main1" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main8} alt="main2" />}</SwiperSlide>
                    <SwiperSlide>{<img src={main9} alt="main3" />}</SwiperSlide>
                    <SwiperSlide>
                      {<img src={main10} alt="main3" />}
                    </SwiperSlide>
                    <SwiperSlide>
                      {<img src={main11} alt="main3" />}
                    </SwiperSlide>
                    <SwiperSlide>
                      {<img src={main12} alt="main3" />}
                    </SwiperSlide>
                    <SwiperSlide>
                      {<img src={main13} alt="main3" />}
                    </SwiperSlide>
                    <SwiperSlide>
                      {<img src={main14} alt="main3" />}
                    </SwiperSlide>
                    <SwiperSlide>
                      {<img src={main15} alt="main3" />}
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
          </div>
        </div>
        <Footer />
        <WhatsappLink />
      </div>
    </>
  );
}
export default Gallery;
