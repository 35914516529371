import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AllProject from './components/AllProject/AllProject';
import About from './components/pagesMenuLinks/About/About';
import Price from './components/pagesMenuLinks/Price/Price';
import Gallery from './components/pagesMenuLinks/Gallery/Gallery';
import Contakt from './components/pagesMenuLinks/Contacts/Contacts';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AllProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/price" element={<Price />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contakt" element={<Contakt />} />
      </Routes>
    </>
  );
}

export default App;
