import Header from '../Header/Container/Header';
import Main from '../Main/Container/Main';
import Footer from '../Footer/Footer';
import './AllProject.css';
import WhatsappLink from '../WhatsappLink/WhatsappLink';

function AllProject() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <Main />
        <Footer />
        <WhatsappLink />
      </div>
    </>
  );
}
export default AllProject;
