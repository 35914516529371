import { useFormik } from 'formik';
import { InputMask } from 'primereact/inputmask';
import { useState } from 'react';
import Vector from '../../../assets/img/vector.png';
import './Forma_zayavka.css';
import { useProject } from '../../../ProjectProvider';

function FormaZayavka() {
  const [isForm, setIsForm] = useState(false);
  const { isScroll } = useProject();

  if (isForm) {
    document.querySelector('body').style.overflow = 'hidden';
  } else {
    document.querySelector('body').style.overflow = 'visible';
  }

  const openForm = () => {
    setIsForm(true);
  };
  const closeForm = () => {
    setIsForm(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Pole musi być wypełnione...';
    }
    if (!values.tel) {
      errors.tel = 'Pole musi być wypełnione...';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      tel: '',
    },
    validate,
    onSubmit: async (values) => {
      const data = {
        name: values.firstName,
        tel: values.tel,
        msg: new Date().toUTCString(),
      };

      let response = await fetch('mail.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      let result = await response.text();
      values.firstName = '';
      values.tel = '';
      alert(result);
      closeForm();
    },
  });

  return (
    <>
      <div
        className={
          !isScroll ? 'header__form-request' : 'header__form-request closed'
        }
      >
        <button
          onClick={() => {
            openForm();
          }}
          className="header__btn-order header__btn-order460px"
          type="button"
        >
          Zamów usługę
        </button>
      </div>
      <div onClick={closeForm} className={isForm ? 'backdrop' : 'hidden'}></div>
      <form className={!isForm ? 'hidden' : ''} onSubmit={formik.handleSubmit}>
        <div className="divImg">
          <img
            onClick={closeForm}
            id="close"
            className="close"
            src={Vector}
            alt="vector"
          />
        </div>
        <h2 id="h2">Wypełnij formularz zamówić rozmowę</h2>
        {formik.touched.firstName && formik.errors.firstName ? (
          <div
            style={{
              color: '#ec5959',
              marginLeft: '12%',
              paddingBottom: '5px',
            }}
          >
            {formik.errors.firstName}
          </div>
        ) : null}
        <input
          className="form__input-item"
          id="firstName"
          name="firstName"
          type="text"
          placeholder="Twoje imię..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
        />
        {formik.touched.tel && formik.errors.tel ? (
          <div
            style={{
              color: '#ec5959',
              marginLeft: '12%',
              paddingBottom: '5px',
            }}
          >
            {formik.errors.tel}
          </div>
        ) : null}

        <InputMask
          id="tel"
          name="tel"
          type="tel"
          mask="+48 999 999 999"
          placeholder="+48___ ___ ___"
          onBlur={formik.handleBlur}
          value={formik.values.tel}
          onChange={formik.handleChange}
        />
        <div className="form__btn">
          <input type="submit" className="btn" value="Zamów rozmowę"></input>
        </div>
      </form>
    </>
  );
}
export default FormaZayavka;
