import './Main.css';
import Services from '../Services/Services';
import Gallery from '../Gallery/Gallery';

function Main() {
  return (
    <>
      <div className="main">
        <div className="main-first">
          <div className="container main-container">
            <Services />
          </div>
        </div>
        <div className="main-second">
          <div className="container main-container">
            <Gallery />
          </div>
        </div>
      </div>
    </>
  );
}
export default Main;
