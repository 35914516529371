import Img_Facebook from '../../../assets/img/Links/facebook.png';
import Img_Instagram from '../../../assets/img/Links/instagram.png';
import Img_Youtube from '../../../assets/img/Links/youtube.png';
import './LinkSocial.css';

import { Link } from 'react-router-dom';

function LinkSocial() {
  return (
    <>
      <div className="header__list-social-media">
        <div className="header__social-link-item">
          <Link
            to="https://www.facebook.com/VitalFly"
            target="_blank"
            alt="facebook"
            title="facebook"
            className="header_facebook"
          >
            <img src={Img_Facebook} alt="facebook" />
          </Link>
        </div>
        <div className="header__social-link-item">
          <Link
            to="https://www.instagram.com/vital_fly?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
            title="instagram"
            alt="instagram"
            target="_blank"
            className="header__instargam"
          >
            <img src={Img_Instagram} alt="instagram" />
          </Link>
        </div>
        <div className="header__social-link-item">
          <Link
            to="https://www.youtube.com/@VitalFly?feature=shared"
            title="youtube"
            alt="youtube"
            target="_blank"
            className="header__youtube"
          >
            <img src={Img_Youtube} alt="youtube" />
          </Link>
        </div>
      </div>
    </>
  );
}
export default LinkSocial;
