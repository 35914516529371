import Header from '../../Header/Container/Header';
import Footer from '../../Footer/Footer';
import about from '../../../assets/img/about.jpg';
import WhatsappLink from '../../WhatsappLink/WhatsappLink';
import './About.css';

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="about">
          <div className="about-title">
            <h1>O nas</h1>
          </div>
          <div className="container about-container">
            <div className="about__content">
              <h2>Nasza pracownia filmów istnieje od 2008 roku.</h2>
              <div className="about__content-img">
                <img src={about} alt="about" />
              </div>
              <div className="about__content-text">
                <p>
                  Dysponujemy profesjonalnym zespołem profesjonalistów, którzy
                  zrobią wszystko, co w ich mocy, aby sprostać Twoim potrzebom.
                  Jesteśmy oficjalną firmą płacącą podatki i odpowiadającą za
                  wszystkie nasze działania.
                </p>
                <p>
                  W naszej kadrze mamy kamerzystów, montażystów, reżyserów,
                  kierowników scen, a jeśli zajdzie taka potrzeba, angażujemy
                  aktorów do zdjęć lub statystów.
                </p>
                <p>
                  Korzystamy wyłącznie z profesjonalnego sprzętu i
                  profesjonalnych programów instalacyjnych.
                </p>
                <p>
                  Mamy ogromne doświadczenie w różnorodnych projektach.
                  Współpracujemy z agencjami rządowymi, klientami biznesowymi i
                  prywatnymi.
                </p>
                <p>
                  Nie zobowiązujemy Cię do wiary we wszystko, co tu jest
                  napisane. Nasze treści można przestudiować, można je „poczuć”
                  na własne oczy i usłyszeć dźwiękiem, aby przekonać się o
                  naszym profesjonalizmie. Potem porozmawiajmy. Poznasz lepiej
                  nasze podejście, zyskasz wartość i zrozumiesz, co mamy do
                  zaoferowania.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <WhatsappLink />
      </div>
    </>
  );
}
export default About;
